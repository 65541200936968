import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyCardComponent } from './person/main-block/my-block/my-card/my-card.component';
import { MyNameComponent } from './person/my-name/my-name.component';
import { SiblingsBlockComponent } from './person/main-block/siblings-block/siblings-block.component';
import { MyBlockComponent } from './person/main-block/my-block/my-block.component';
import { FamilyBlockComponent } from './person/main-block/family-block/family-block.component';
import { FamilyItemComponent } from './person/main-block/family-block/family-item/family-item.component';
import { ChildrenBlockComponent } from './person/main-block/family-block/family-item/children-block/children-block.component';
import { MainBlockComponent } from './person/main-block/main-block.component';
import { AncestorsBlockComponent } from './person/ancestors-block/ancestors-block.component';
import { PersonComponent } from './person/person.component';
import { ApiService } from './service/api/api.service';
import { HttpClientModule } from '@angular/common/http';
import { AncestorComponent } from './person/ancestors-block/ancestor/ancestor.component';
import { AncestorParentsComponent } from './person/ancestors-block/ancestor-parents/ancestor-parents.component';
import { FamilyMemberComponent } from './person/main-block/family-member/family-member.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AncestorsBlockComponent,
    AncestorComponent,
    AncestorParentsComponent,
    AppComponent,
    ChildrenBlockComponent,
    FamilyBlockComponent,
    FamilyItemComponent,
    FamilyMemberComponent,
    MainBlockComponent,
    MyBlockComponent,
    MyCardComponent,
    MyNameComponent,
    PageNotFoundComponent,
    PersonComponent,
    SiblingsBlockComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule
  ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule {
  AppComponent;
}
