import {Component, OnInit} from '@angular/core';
import {ApiService} from '../service/api/api.service';
import {PersonPage} from '../dto/PersonPage';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {
  data: PersonPage;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    let id = +this.activatedRoute.snapshot.paramMap.get('id');
    if (!id) {
      id = 0;
    }
    this.api.getPersonPage(id).pipe().subscribe(result => {
      this.data = result;
    }, error => {
        console.error(error);
        window.location.href = '/login';
    });
  }

}

