import {Component, Input, OnInit} from '@angular/core';
import {PartnerAndChildren} from '../../../dto/PartnerAndChildren';

@Component({
  selector: 'app-family-block',
  templateUrl: './family-block.component.html',
  styleUrls: ['./family-block.component.scss']
})
export class FamilyBlockComponent implements OnInit {
  @Input() data: PartnerAndChildren[];
  constructor() { }

  ngOnInit(): void {
  }

}
