import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-my-name',
  templateUrl: './my-name.component.html',
  styleUrls: ['./my-name.component.scss']
})
export class MyNameComponent implements OnInit {
  @Input() name: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
