import {Component, Input, OnInit} from '@angular/core';
import {PersonDto} from '../../../dto/PersonDto';

@Component({
  selector: 'app-family-member',
  templateUrl: './family-member.component.html',
  styleUrls: ['./family-member.component.scss']
})
export class FamilyMemberComponent implements OnInit {
  @Input() data: PersonDto;
  constructor() { }

  ngOnInit(): void {
  }

}
