<div *ngIf="data" class="relative">
  <p class="role">{{data.role}}</p>
  <div class="relative-photo-wrapper">
      <div *ngIf="data.photoUrl" class="relative-photo-container">
          <img alt="" class="relative-photo" src="{{data.photoUrl}}">
      </div>
      <div *ngIf="!data.photoUrl" class="relative-photo-container">
      <img src="../../../../assets/img/male-placeholder.png" alt="" class="relative-photo" *ngIf="data.gender">
      <img src="../../../../assets/img/female-placeholder.png" alt="" class="relative-photo" *ngIf="!data.gender">
    </div>
  </div>
  <p class="relative-name">{{data.name + ' ' + data.fathername}}</p>
    <p class="relative-surname">{{ data.surname }}</p>
  <div class="relative-lifespan">
    <p class="relative-birth">{{data.birthday | date: 'dd.MM.yyyy'}}</p>
      <p *ngIf="data.deadDay">-</p>
    <p class="relative-death" *ngIf="data.deadDay">{{data.deadDay | date: 'dd.MM.yyyy'}}</p>
  </div>
  <div class="relative-description">
    <a *ngIf="data.id" href="/person/{{data.id}}" class="goto">Перейти на страницу</a>
    <p class="text" *ngIf="data.description">{{data.description}}</p>
    <p class="text no-description" *ngIf="!data.description">Нет описания</p>
  </div>
</div>
<div *ngIf="!data" class="relative">
    <p class="role">Тут будет кнопка добавить нового человека.</p>
</div>

