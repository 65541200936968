import {Component, Input, OnInit} from '@angular/core';
import {PersonPage} from '../../dto/PersonPage';

@Component({
  selector: 'app-main-block',
  templateUrl: './main-block.component.html',
  styleUrls: ['./main-block.component.scss']
})
export class MainBlockComponent implements OnInit {
  @Input() data: PersonPage;
  constructor() { }

  ngOnInit(): void {
  }

}
