import {Component, Input, OnInit} from '@angular/core';
import {PersonParentDto} from '../../../dto/PersonParentDto';

@Component({
  selector: 'app-ancestor',
  templateUrl: './ancestor.component.html',
  styleUrls: ['./ancestor.component.scss']
})
export class AncestorComponent implements OnInit {
  @Input() data: PersonParentDto;
  constructor() {
  }

  ngOnInit(): void {
  }

}
