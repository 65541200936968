<div class="me">
  <p class="role">{{data.role}}</p>
  <div class="my-photo-wrapper">
      <div *ngIf="data.photoUrl" class="my-photo-container">
          <img alt="" class="my-photo" src="{{data.photoUrl}}">
    </div>
      <div *ngIf="!data.photoUrl" class="my-photo-container">
          <img *ngIf="data.gender" alt="" class="my-photo" src="../../../../../assets/img/male-placeholder.png">
          <img *ngIf="!data.gender" alt="" class="my-photo" src="../../../../../assets/img/female-placeholder.png">
      </div>
  </div>
  <p class="my-name">{{data.name + ' ' + data.fathername}}</p>
    <p class="my-surname">{{ data.surname }}</p>
  <div class="my-lifespan">
    <p class="my-birth">{{data.birthday | date: 'dd.MM.yyyy'}}</p>
      <p *ngIf="data.deadDay">-</p>
    <p class="my-death" *ngIf="data.deadDay">{{data.deadDay | date: 'dd.MM.yyyy'}}</p>
  </div>
  <div class="my-description">
    <p class="text" *ngIf="data.description">{{data.description}}</p>
    <p class="text no-description" *ngIf="!data.description">Нет описания</p>
  </div>
</div>
