import {Component, Input, OnInit} from '@angular/core';
import {PartnerAndChildren} from '../../../../dto/PartnerAndChildren';

@Component({
  selector: 'app-family-item',
  templateUrl: './family-item.component.html',
  styleUrls: ['./family-item.component.scss']
})
export class FamilyItemComponent implements OnInit {
  @Input() data: PartnerAndChildren;
  constructor() { }

  ngOnInit(): void {
  }

}
