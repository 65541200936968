import {Component, Input, OnInit} from '@angular/core';
import {PersonDto} from '../../../dto/PersonDto';

@Component({
  selector: 'app-my-block',
  templateUrl: './my-block.component.html',
  styleUrls: ['./my-block.component.scss']
})
export class MyBlockComponent implements OnInit {
  @Input() data: PersonDto;
  constructor() { }

  ngOnInit(): void {
  }

}
