import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {PersonPage} from '../../dto/PersonPage';

@Injectable()
export class ApiService {

  constructor(private http: HttpClient) {
  }

  getPersonPage(personId: number) {
    return this.http.get<PersonPage>(`/api/person/${personId}`);
  }
}
