import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { PersonComponent } from './person/person.component';

const routes: Routes = [
  {path: '', component: PersonComponent},
  {path: 'person/:id', component: PersonComponent},
  {
    path: '**', component: PageNotFoundComponent, data: {breadcrumb: 'page-not-found', roles: []}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
