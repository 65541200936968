<app-my-name *ngIf="data" [name]="data.person.name + ' ' + data.person.fathername + ' ' + data.person.surname">

</app-my-name>

<app-ancestors-block *ngIf="data" [data]="data">

</app-ancestors-block>

<app-main-block *ngIf="data" [data]="data">

</app-main-block>
