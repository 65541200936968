import {Component, Input, OnInit} from '@angular/core';
import {PersonDto} from '../../../dto/PersonDto';

@Component({
  selector: 'app-siblings-block',
  templateUrl: './siblings-block.component.html',
  styleUrls: ['./siblings-block.component.scss']
})
export class SiblingsBlockComponent implements OnInit {
  @Input() data: PersonDto[];
  constructor() { }

  ngOnInit(): void {
  }

}
