import {Component, Input, OnInit} from '@angular/core';
import {PersonPage} from '../../dto/PersonPage';

@Component({
  selector: 'app-ancestors-block',
  templateUrl: './ancestors-block.component.html',
  styleUrls: ['./ancestors-block.component.scss']
})
export class AncestorsBlockComponent implements OnInit {
  @Input() data: PersonPage;
  constructor() { }

  ngOnInit(): void {
  }

}
