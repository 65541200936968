import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '(window:resize)': 'onWindowResize($event)'
  }

})
export class AppComponent implements OnInit {
  ngOnInit(): void {
  }
}
