import {Component, Input, OnInit} from '@angular/core';
import {PersonDto} from '../../../../../dto/PersonDto';

@Component({
  selector: 'app-children-block',
  templateUrl: './children-block.component.html',
  styleUrls: ['./children-block.component.scss']
})
export class ChildrenBlockComponent implements OnInit {
  @Input() data: PersonDto[];
  constructor() { }

  ngOnInit(): void {
  }

}
