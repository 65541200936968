<div class="ancestors-block">
  <div class="ancestor-container">
      <app-ancestor-parents *ngIf="data.father" [data]="data.father"></app-ancestor-parents>
      <app-ancestor *ngIf="data.father" [data]="data.father"></app-ancestor>
  </div>
  <span class="plus">+</span>
  <div class="ancestor-container">
      <app-ancestor-parents *ngIf="data.mother" [data]="data.mother"></app-ancestor-parents>
      <app-ancestor *ngIf="data.mother" [data]="data.mother"></app-ancestor>
  </div>
</div>
