import {Component, Input, OnInit} from '@angular/core';
import {PersonParentDto} from '../../../dto/PersonParentDto';

@Component({
  selector: 'app-ancestor-parents',
  templateUrl: './ancestor-parents.component.html',
  styleUrls: ['./ancestor-parents.component.scss']
})
export class AncestorParentsComponent implements OnInit {
  @Input() data: PersonParentDto;
  constructor() { }

  ngOnInit(): void {
  }

}
