<div class="family-member">
  <div class="left">
    <p class="role">{{data.role}}</p>
    <div class="photo-wrapper">
        <div *ngIf="data.photoUrl" class="photo-container">
            <img alt="" class="photo" src="{{data.photoUrl}}">
        </div>
        <div *ngIf="!data.photoUrl" class="photo-container">
        <img src="../../../../assets/img/male-placeholder.png" alt="" class="photo" *ngIf="data.gender">
        <img src="../../../../assets/img/female-placeholder.png" alt="" class="photo" *ngIf="!data.gender">
      </div>
    </div>
    <p class="name">{{data.name + ' ' + data.fathername}}</p>
      <p class="surname">{{ data.surname }}</p>
  </div>
  <div class="right">
    <div class="description">
      <a *ngIf="data.id" href="/person/{{data.id}}" class="goto">Перейти на страницу</a>
      <p class="text" *ngIf="data.description">{{data.description}}</p>
      <p class="text no-description" *ngIf="!data.description">Нет описания</p>
    </div>
    <div class="lifespan">
      <p class="birth">{{data.birthday | date: 'dd.MM.yyyy'}}</p>
        <p *ngIf="data.deadDay">-</p>
      <p class="relative-death" *ngIf="data.deadDay">{{data.deadDay | date: 'dd.MM.yyyy'}}</p>
    </div>
  </div>
</div>
